
<template>
  <div>

    <div class="block w-full text-right mr-2 mb-8">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
    </div>
    <vx-card id="ecommerce-checkout-demo" class="bg-h-primary-light">
      <!-- ServerTpe: Default -->
    <!-- <div v-if=" serverType == 'default' || serverLocation == 'default'">
      <div class="flex gap-3 flex-col text-lg text-center">
        لطفا به داشبورد برگردید و نوع سرور خود را با کلیک بر روی دکمه سرویس جدید مشخص نمایید.
      </div>
    </div> -->
    <!-- ServerType: Linux - IRAN -->
    <div class="my-5 bg-h-primary p-5 rounded-lg flex w-full" >

      <b-form @submit.stop.prevent="addInstance()" class="w-full">

        <div class="md:flex justify-between gap-4 mb-10" style="direction: ltr;">
          <div class="w-full md:w-1/2">
            <span class=" block"> نام هاست</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25" placeholder="Host Name" v-model="form.hostName"  v-on:keypress="isLetter($event)" required>
            <span class="text-sm text-muted block"> فقط لاتین و بیشتر از 3 حرف باشد</span>
          </div>
          <div class="w-full md:w-1/2">
            <span class="block"> نام سرور</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25" placeholder="Server Name" v-model="form.serverName" v-on:keypress="isLetter($event)" required>
            <span class="text-sm text-muted block"> فقط لاتین و بیشتر از 3 حرف باشد</span>
          </div>


        </div>

        <div class="w-full bg-h-primary-light p-2 md:p-5 rounded border-white border-dashed border-opacity-25 border">

          <div class="md:flex text-sm md:text-base justify-between mb-8 ">  موقعیت مکانی را انتخاب کنید:

            <div class="md:right-0 ">
                <b-button @click="getLocations()" class="w-full mt-2">تازه سازی</b-button>
              </div>
          </div>
          <div class="">
            <!-- Disabled Location -->
            <div class="lg:flex block w-full text-center justify-start gap-3">

              <!-- <b-form-radio  class="w-full block m-auto mb-2" button button-variant="light" v-for= "(location, id) in locations" :key="id" v-if="(location.ip_active === 0)" disabled >
                <div class="md:flex w-full md:items-center md:gap-2 px-3 md:px-6 py-2 rounded m-auto block ">
                  <img  width="50" class="m-auto" :src="`data:image/png;base64,${location.country_flag_file.attachment_content}`" />
                  {{location.country_name}}
                  <span class="text-xs block">(ظرفیت تکمیل)</span>
                </div>
              </b-form-radio> -->

              <!-- Enabled Location -->
              <b-form-radio
                class="mb-2 md:mb-0 block w-full"
                :disabled="location.ip_active === 1  ? false : true"
                button
                button-variant="outline-light"
                v-model="form.selectedlocation"
                v-for= "(location, id) in locations" :key="id"
                @change="getDatacenters(location.id)" :value="location.id">
                <div class="md:flex w-full items-center gap-2 px-6 py-2 rounded  ">

                  <img  width="50" class="mb-2 md:mb-0" :src="`data:image/png;base64,${location.country_flag_file.attachment_content}`" />
                  {{location.country_name}}
                  <span v-if="(location.ip_active === 0)"  class="text-xs block">(ظرفیت تکمیل)</span>

               </div>
              </b-form-radio>

          </div>
          <div v-if="(form.selectedlocation !== '' && datacenters !== '')" class="text-center">

            <span class="text-white block my-4">دیتاسنتر را انتخاب کنید:</span>
            <b-form-radio
            class="ml-2 my-2 block m-auto"
            :disabled="datacenter.ip_active === 1  ? false : true"
            button
            button-variant="outline-light"
            v-model="form.selectedDatacenter"
            :key="id" v-for="(datacenter, id) in datacenters"
            @change="getImageList(datacenter.id)"

            :value="datacenter.id">
            <span class="p-3">


              {{datacenter.title}}            <span v-if="(datacenter.ip_active == 0)"  class="text-xs ">(ظرفیت تکمیل)</span>

            </span>
          </b-form-radio>
        </div>

          </div>
        </div>

        <div  v-if="(form.selectedDatacenter > 0)" class="mt-3 mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
        <b-tabs v-if="(form.selectedDatacenter > 0)" content-class="mt-3" class="mb-3 " >
          <!-- <div class="text-sm md:text-base mb-8">  سیستم عامل را انتخاب کنید:</div> -->

          <b-tab class="p-5" title="سیستم عامل" v-if="(form.selectedDatacenter > 0 & this.imageList.OS.length > 0)" active>
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">

              <div :key="os" v-for="(os) in imageList.OS" @change="getVersions('OS')">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedOS" :key="os"
                  :value="os.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">

                      <img  width="50" class="m-auto md:m-0 mb-2 md:mb-0" :src="`data:image/png;base64,${os.template_logo_file.attachment_content}`" />
                      {{os.title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>

          </b-tab>
          <b-tab class="text-white  " title="اپلیکیشن آماده" v-if="(form.selectedDatacenter > 0 & this.imageList.APP.length > 0)">
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center ">
              <div :key="app" v-for="(app) in imageList.APP" @change="getVersions('APP')">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedAPP" :key="app"
                    :value="app.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0 app-app-radio">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">

                      <img  width="50" class="m-auto md:m-0 mb-2 md:mb-0" :src="`data:image/png;base64,${app.template_logo_file.attachment_content}`" />
                      {{app.title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>
          </b-tab>
          <b-tab class="text-white" title="ایزو" v-if="(form.selectedDatacenter > 0 & this.imageList.ISO.length > 0)">
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center">

              <div :key="iso" v-for="(iso) in imageList.ISO" @change="getVersions('ISO')">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedISO" :key="iso"
                  :value="iso.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">

                      <img  width="50" class="m-auto md:m-0 mb-2 md:mb-0" :src="`data:image/png;base64,${iso.template_logo_file.attachment_content}`" />
                      {{iso.title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        </div>

        <div  v-if="(form.selectedOS !== '' || form.selectedAPP !== '')"  class="mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
          <span class="mb-8 block text-sm md:text-base">نسخه سیستم عامل را انتخاب کنید:</span>
          <div class="md:flex justify-start gap-2 ">

            <b-form-radio  v-model="form.selectedVersion" @change="getResources()" :key="v" v-for="(v) in versions"  :value="v" button button-variant="outline-light" class="text-center m-auto md:text-right block mb-2 md:m-0">
              <span class="px-8">
                {{ v }}
              </span>
            </b-form-radio>
          </div>
        </div>
        <div v-if="(form.selectedVersion !== '')" class="mb-3 w-full relative bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
          <label for="resources" class="block mb-8 text-sm md:text-base">منابع مورد نیاز را انتخاب کنید:</label>
          <!-- <b-form-input class="" id="resources"  v-on:input="getResources(form.resource)" v-model="form.resource" type="range" :min="0" max="10" step="1" ></b-form-input>

          <div class="custom-text bg-grey-light text-h-primary absolute w-48 right-0 p-2 rounded-lg rounded-t-sm text-right" :style="{ right: form.resource * 9 + '%' }">
            <span class=" text-sm"> Ram: {{ ram }} | </span>
            <span class=" text-sm"> CPU: {{ cpu }} | </span>
            <span class=" text-sm"> Hard: {{ hard }}</span>
            </div> -->
            <div class="lg:flex md:gap-8">


            <div class="w-full mt-3 lg:mt-0 lg:w-1/3">
              <span class="">RAM: {{ ram }}GB</span>
              <vs-slider color="secondary" text-fixed="GB" id="ram"  v-model="ram" :min="hw_req.min_ram" :max="hw_req.max_ram" step="1" ></vs-slider>
            </div>
            <div class="w-full mt-3 lg:mt-0 lg:w-1/3">
              <span class="">CPU: {{ cpu }}Core</span>
              <vs-slider color="secondary" text-fixed="Core" id="cpu"  v-model="cpu" type="range" :min="hw_req.min_vcpu" :max="hw_req.max_vcpu" step="1" ></vs-slider>
            </div>
            <div class="w-full mt-3 lg:mt-0 lg:w-1/3">
              <span class="">HARD: {{ hard }}GB</span>
              <vs-slider  color="secondary" text-fixed="GB" id="hard"  v-model="hard" type="range" :min="hw_req.min_hard" :max="hw_req.max_hard" step="5" ></vs-slider>
            </div>
          </div>

        </div>
        <div v-if="isSshFromActive" class="mt-10 w-full relative bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border" title="انتخاب کلید ssh">
          <span class="text-sm md:text-base">انتخاب کلید ssh برای افزودن به سرور </span>
          <vs-select
            v-if="sshKeyList.length > 0"
             v-model="sshKeyId"
              autocomplete
              class="selectExample w-full mt-5"
              label="عنوان کلید  : "
            >
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.ssh_key_title"
                v-for="(item, index) in sshKeyList"
              />
            </vs-select>
            <b-button v-else target="_blank" to="/apps/addKey" class="mt-4 w-full border-0 bg-h-secondary text-white">
              افزودن کلید ssh</b-button>
          </div>

        <b-row v-if="(form.selectedVersion !== '')"  class="block md:flex mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border w-full m-auto">

      <b-col md="6" class="mt-3 text-center md:text-right" style="text-align: right !important; direction: rtl;">
        <b-form-group label="">
          <b-form-static class="text-xl lg:text-3xl ">
            {{ prices | money }}
            <span class="text-sm"> تومان/ساعت</span> <br>


            {{ monthPrice | money }}
            <span class="text-sm"> تومان/ماهیانه</span> <br>

            <span v-if="[3, 9, 14, 15].includes(this.form.selectedDatacenter)" class="text-sm text-warning">
              <a class="text-success" href="https://www.haio.ir/traffic-price/" target="_blank">توضیحات تکمیلی</a>
              هزینه ترافیک مصرفی در دیتاسنترهای ایران جداگانه محاسبه می‌شود.
            </span>
            <p v-else class=" text-h-success ">
              {{ ((prices * 24) * 7) | money }}
              <span class="text-sm"> تومان/یک هفته</span> <br>
            </p>

          </b-form-static>

        </b-form-group>
      </b-col>
      <b-col md="6" class="border-0 border-t md:border-t-0 md:border-l border-dashed border-white border-opacity-25 pl-3 pt-3 md:pt-0">
        <b-form-group >
          <div class="text-left">
            <b-form-checkbox v-model="isSshFromActive"  class="rounded-pill m-auto">استفاده از کلید SSH <span class="text-muted">(اختیاری)</span></b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group v-if="[3, 14, 15].includes(this.form.selectedDatacenter)" >
          <div class="text-left">
            <b-form-checkbox v-model="freeBackup"  class="rounded-pill m-auto">فعال سازی بک آپ رایگان؟ </b-form-checkbox>
          </div>
        </b-form-group>

        </b-col>
        <b-col cols="12" lg="12" md="12" class="text-center md:text-right flex justify-end gap-1">
          <b-button @click="howToCal = !howToCal" class=" ">نحوه محاسبه</b-button>
          <b-button type="submit" variant="primary" class="block text-base md:text-lg px-5 md:px-10 py-3">ساخت سرور</b-button>
        </b-col>
    </b-row>
    <b-row v-if="(howToCal)"  class="block mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border w-full m-auto">
                  <div class="mt-4">
              <div class="w-full">
                <table class="table table-striped table-dark table-hover w-full rounded ">
                  <thead class="rounded">
                    <tr class="rounded text-center">
                      <th scope="col">منابع</th>
                      <th scope="col">مقدار انتخابی</th>
                      <th scope="col">قیمت هر واحد</th>
                      <th scope="col">قابل پرداخت (مقدار x قیمت)</th>
                    </tr>
                  </thead>
                  <tbody class="text-center ">
                    <tr scope="row">
                      <td>RAM</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  ram  }}GB</td>
                      <td>{{ ramPrice }} تومان</td>
                      <td>{{ ram * ramPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>CPU</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  cpu  }}Core</td>
                      <td>{{ cpuPrice }} تومان</td>
                      <td>{{ cpu * cpuPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>HARD</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  hard  }}GB</td>
                      <td>{{ hardPrice }} تومان</td>
                      <td>{{ hard * hardPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>IP</td>
                      <td>1 عدد</td>
                      <td>{{ ipPrice }} تومان</td>
                      <td>{{ 1 * ipPrice }} تومان/ساعت</td>
                      </tr>

                    <tr scope="row" >
                      <td colspan="3" class="text-center">جمع کل مبلغ قابل پرداخت در هر ساعت:</td>
                      <td class="bg-h-secondary text-white rounded-lg ">{{ ram * ramPrice +  cpu * cpuPrice + hard * hardPrice + ipPrice | money }} تومان/ساعت</td>

                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
            </b-row>

      </b-form>
    </div>

  </vx-card>
  <vs-popup :active.sync="deletePrompt" title="توجه">
        <div class="con-exemple-prompt">
          <div>
            <p>جهت ساخت سرور در این لوکیشن هزینه یک هفته بصورت پیش پرداخت از موجودی شما کسر میشود و پس از آن بصورت اتوماتیک هزینه ساعاتی کسر میگردد.</p>

          </div>
        </div>
      </vs-popup>

</div>


</template>

<script>
const names = require('file-loader!./names.txt')

export default {
  data () {
    return {
      form: {
        serverName: '',
        hostName: '',
        names: [],
        selectedlocation: '',
        selectedDatacenter:'',
        selectedOS:'',
        selectedAPP: '',
        selectedVersion:'',
        resource:0

      },
      howToCal: false,
      locations: {},
      location:'',
      imageList: {
        OS: '',
        APP: '',
        ISO: ''
      },
      // datacenter: '',
      datacenters: [],
      datacenter_id:'',
      ram:'',
      cpu:'',
      hard:'',
      isSshFromActive: false,
      sshKeyId  : '',
      freeBackup  : false,
      sshKeyList : [],
      versions: [],
      os:'',
      hw_req:[],
      cpuPrice:'',
      hardPrice:'',
      ramPrice:'',
      ipPrice:'',
      activePrompt: 0,
      deletePrompt: 0

    }
  },
  computed: {
    imagesData () {
      return this.imageList
    },
    serverType () {
      return this.$route.params.serverType || 'default'
    },
    serverLocation () {
      return this.$route.params.location || 'default'
    },
    monthPrice () {
      return parseInt(this.prices) * 24 * 30
    },
    prices () {
      return (
        parseInt(this.cpuPrice * this.cpu) +
        parseInt(this.hardPrice * this.hard) +
        parseInt(this.ramPrice * this.ram) +
        parseInt(this.ipPrice)

      )
    },
    validateIP () {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.vpcSubnet)) {
        return true
      } else {

        return false
      }
    }
  },
  watch: {
    'form.selectedlocation': 'resetDatacenter',
    isSshFromActive () {
      this.getSSHKeyList()
    }

  },
  methods: {
    setSetupIPPrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true

    },
    resetDatacenter () {
      // alert('locarnio cahnged')
      // alert(this.form.selectedDatacenter)
      this.form.selectedDatacenter = ''
    },
    isLetter (e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Z-a-z0-9]+$/.test(char)) return true // Match with regex
      else this.sweetAlert(['خطا در نام سرور', 'لطفا بدون فاصله و فقط لاتین تایپ کنید', 'باشه', 'false']) // If not match, don't add to input text
    },
    sweetAlert (txt) {
      if (txt[3] !== 'false') {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        }).then(() => {
          this.$router.push(txt[3])
        })
      } else {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        })
      }
    },
    onSubmit (event) {
      event.preventDefault()
      // alert(JSON.stringify(this.form))
    },
    getLocations () {
      this.$vs.loading()
      this.form.selectedOS = ''
      this.form.selectedAPP = ''
      this.form.selectedlocation = ''
      this.form.selectedVersion = ''
      this.$http
        .get('/cloud/location')
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params
          const parsedobj = JSON.stringify(data.data)
          const p = JSON.parse(parsedobj)
          this.locations = p

        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message
          })
        })
    },
    getDatacenters (id) {
      this.form.selectedOS = ''
      this.form.selectedAPP = ''
      this.form.selectedVersion = ''
      // alert(id)
      // dirty Code!
      this.datacenters = this.locations.find((car) => car.id === parseInt(id)).datacenters
      this.setDatacenter(this.datacenters)
    },
    setDatacenter (value) {
      this.datacenter_id = value.datacenter_id
      // this.datacenter_title = value.datacenter_location_name
      if (this.form.selectedDatacenter > 0) {

        this.getImageList(this.datacenter_id)
      }
    },
    getImageList (id) {
      this.form.selectedVersion = ''
      this.form.selectedOS = ''
      this.form.selectedAPP = ''
      // this.imageList = {}
      this.$vs.loading()
      this.$http
        .get(`/cloud/images?type=0&datacenter_id=${id}`)
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params
          // console.log('data as data', data)
          const parsedobj = JSON.stringify(data.data)
          const p = JSON.parse(parsedobj)
          this.imageList = p
          this.form.selectedOS = ''
          this.form.selectedAPP = ''
          this.selectedVersion = ''

        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message
          })
        })
    },
    // getImageList (id) {
    //   this.form.selectedVersion = ''

    //   this.$vs.loading()
    //   this.$http
    //     .get(`/cloud/image/template?type=0&datacenter_id=${id}`)
    //     .then((res) => {
    //       this.$vs.loading.close()
    //       const data = res.data.params
    //       // console.log('data as data', data)
    //       const parsedobj = JSON.stringify(data.data)
    //       const p = JSON.parse(parsedobj)
    //       this.imageList = p
    //       this.form.selectedOS = ''

    //       this.selectedVersion = ''
    //     })
    //     .catch((err) => {
    //       this.$vs.loading.close()
    //       this.$vs.notify({
    //         color: 'danger',
    //         title: err.response.data.message
    //       })
    //     })
    // },
    getVersions (type) {
      // os-app-radio
      // document.getElementsByClassName('os-app-radio').checked = false
      this.form.selectedVersion = ''
      switch (type) {
      case 'OS':
        this.form.selectedAPP = ''
        this.versions = this.imageList.OS.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')

        break

      case 'APP':
        this.form.selectedOS = ''
        this.versions = this.imageList.APP.find((version) => version.id === parseInt(this.form.selectedAPP)).versions.split(',')

        break

      default:
        break

      }
      if (![3, 9, 14, 15].includes(this.form.selectedDatacenter)) {
        this.deletePrompt = 1
      }
      this.getPrice(this.form.selectedDatacenter)
    },
    // getResources (selectedPack) {
    //   const pack = selectedPack

    //   this.os = this.imageList.find((Os) => Os.id === parseInt(this.form.selectedOS))
    //   this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedOS))

    //   if (this.os.title === 'Windows') {
    //     this.ram = this.hw_req.min_ram
    //     this.cpu = this.hw_req.min_vcpu
    //     this.hard = this.hw_req.min_hard
    //     switch (pack) {
    //     case '1':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '40'
    //       break
    //     case '2':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '50'
    //       break
    //     case '3':
    //       this.ram = '2'
    //       this.cpu = '3'
    //       this.hard = '50'
    //       break
    //     case '4':
    //       this.ram = '3'
    //       this.cpu = '3'
    //       this.hard = '55'
    //       break
    //     case '5':
    //       this.ram = '3'
    //       this.cpu = '3'
    //       this.hard = '60'
    //       break
    //     case '6':
    //       this.ram = '3'
    //       this.cpu = '4'
    //       this.hard = '60'
    //       break
    //     case '7':
    //       this.ram = '4'
    //       this.cpu = '4'
    //       this.hard = '80'
    //       break
    //     case '8':
    //       this.ram = '5'
    //       this.cpu = '4'
    //       this.hard = '100'
    //       break
    //     case '9':
    //       this.ram = '6'
    //       this.cpu = '4'
    //       this.hard = '110'
    //       break
    //     case '10':
    //       this.ram = '6'
    //       this.cpu = '6'
    //       this.hard = '130'
    //       break

    //     default:
    //       break
    //     }

    //   } else {
    //     this.ram = this.hw_req.min_ram
    //     this.cpu = this.hw_req.min_vcpu
    //     this.hard = this.hw_req.min_hard
    //     switch (pack) {
    //     case '1':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '20'
    //       break

    //     default:
    //       break
    //     }
    //   }
    //   this.versions = this.imageList.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')
    //   this.getPrice(this.form.selectedDatacenter)
    // },
    getResources () {
      //const pack = selectedPack
      this.os = ''

      if (this.form.selectedOS !== '') {
        this.os = this.imageList.OS.find((Os) => Os.id === parseInt(this.form.selectedOS))
        // this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedOS))
        // if (this.form.selectedOS !== '') {
        this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.version === this.form.selectedVersion)

        this.ram = this.hw_req.min_ram
        this.cpu = this.hw_req.min_vcpu
        this.hard = this.hw_req.min_hard
        // }


        this.versions = this.imageList.OS.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')
      }

      if (this.form.selectedAPP !== '') {
        this.os = this.imageList.APP.find((Os) => Os.id === parseInt(this.form.selectedAPP))
        // this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedVersion))
        this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.version === this.form.selectedVersion)

        this.ram = this.hw_req.min_ram
        this.cpu = this.hw_req.min_vcpu
        this.hard = this.hw_req.min_hard
      }

      this.versions = this.imageList.APP.find((version) => version.id === parseInt(this.form.selectedAPP)).versions.split(',')

      this.getPrice(this.form.selectedDatacenter)
    },
    async addInstance () {
      // if (this.activePrompt === 1) {
      const insData = {
        ssh_key_id : this.sshKeyId,
        free_backup : (this.freeBackup ? 1 : 0),
        vm_title: this.form.serverName,
        vm_hostname: this.form.hostName,
        template_id: (this.form.selectedOS !== '' ? this.form.selectedOS : this.form.selectedAPP),
        vm_vcpu: this.cpu,
        vm_ram: this.ram,
        vm_hard: this.hard,
        template_version: this.form.selectedVersion,
        datacenter_id: this.form.selectedDatacenter,
        // datacenter_title: this.form.selectedDatacenter[1],
        wan: 1,
        ipv6: 1
      }

      this.$vs.loading()
      try {
        // eslint-disable-next-line no-unused-vars
        await this.$http.post('/cloud/vm', insData)
        this.sweetAlert(['سرور ساخته شد', 'اطلاعات ورود به سرور به ایمیل شما ارسال خواهد شد', 'لیست سرورها', '/instance/list'])

      } catch (err) {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      }

      this.$vs.loading.close()
      // }


    },
    getPrice (id) {
      const data = { cpu: 1, ram: 1, storage: 5 }
      this.$http
        .get(`/cloud/plan?datacenter_id=${id}`, data)
        .then((res) => {
          const a = res.data.params.data[0]
          this.price = a

          this.cpuPrice = parseInt(a.vcpu_price)
          this.hardPrice = parseInt(a.hard_price)
          this.ramPrice = parseInt(a.ram_price)
          this.ipPrice = parseInt(a.public_ip_price)

        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: ' خطا',
            text: err.response.data.message
          })
        })
    },

    updateItemQuantity (event, index) {
      const itemIndex = Math.abs(index + 1 - this.cartItems.length)
      this.$store.dispatch('eCommerce/updateItemQuantity', {
        quantity: event,
        index: itemIndex
      })
    },
    getSSHKeyList () {
      this.$http.get('/ssh/key/list').then((res) => {
        this.sshKeyList = res.data.params.data
      }).catch(() => {

      })
    }

  },

  components: {

  },
  mounted () {


  },
  created () {
    this.getLocations()
    fetch(names)
      .then(response => response.text())
      .then(data => {
        this.names = data.split(',')
        this.form.hostName = this.names[Math.floor(Math.random() * this.names.length)]
        this.form.serverName = this.names[Math.floor(Math.random() * this.names.length)]
      })

  }
}
</script>
<style>
.vs-slider--circle {border-radius: 50% }
[dir=rtl] .text-circle-slider.vs-slider--circle-text{    font-family: sans-serif;
    font-size: 16px;
    direction: ltr !important;
}
.theme-dark .con-vs-slider .vs-slider {
    background: #ffffffa1;
    height: 6px;
    border-radius: 50px;
}
.nav-item a {
  color: #696b6d !important;
}
</style>
